<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form label-width="80px" @submit.native.prevent="search" >
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">当前：应收客户账-客户明细(开票专用)-{{formData.company_name}}</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :sm="12" :md="3">
                                <el-date-picker
                                        style=" margin-top: 8px; width: 220px"
                                        v-model="pickerDate"
                                        type="daterange"
                                        ref="pickerDate"
                                        size="mini"
                                        :clearable="false"
                                        :picker-options="pickerOptions"
                                        @change="searchNewUrl()"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        align="right">
                                </el-date-picker>
                            </el-col>
                            <el-col :sm="12" :md="6">
                                <el-form-item label="公司:">
                                    <el-select v-model="formData.company_name"
                                               @focus="$_searchCustomerList('')"
                                               @change="companyNameChangeEvent($event,'customer_name'),searchNewUrl()"
                                               style="margin-left: 12px;width: 100%"
                                               size="mini"
                                               default-first-option no-data-text="没有搜索结果!" placeholder="可搜索查询"
                                               filterable remote
                                               :remote-method="$_searchCustomerList">
                                        <el-option v-for="(item,key) in $store.getters.getCustomerList" :key="key"
                                                   :label="item.customer_name" :value="item">
                                            <span style="margin-right:8px;">{{item.customer_name}}</span>
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="5">
                                <el-button style=" margin-top: 8px; " type="primary" round @click="search()"
                                           size="mini">搜索
                                </el-button>
                                <el-button type="primary" size="mini" @click="$_openBill($refs.xGrid.getCurrentRecord())"
                                           :disabled="($refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null || $refs.xGrid.getCurrentRecord().type_name == '发票单')"
                                           style="margin-bottom: 2px;margin-top: 2px">查看
                                </el-button>

                                <vxe-toolbar class="pull-left" style=" height: 42px; margin-left: 12px" custom export
                                             ref="xToolbar2">
                                </vxe-toolbar>
                            </el-col>
                            <el-col :sm="12" :md="3">
                                <el-form-item label="上期数:">
                                    <el-input v-model="before_balance_company" :readonly="true" size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="3">
                                <el-form-item label="结存数:">
                                    <el-input v-model="end_balance_company" :readonly="true" size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :sm="12" :md="4">
                                <el-form-item label="所选进金额:" label-width="100px">
                                    <el-input size="mini" :readonly="true"
                                              :value="formData.total_amount?formData.total_amount:0"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="4">
                                <el-form-item label="所选多(少)开票金额:" label-width="140px">
                                    <el-input size="mini" :readonly="true"
                                              :value="this.$XEUtils.commafy(formData.total_balance_money ?formData.total_balance_money :0, { digits: 2})"
                                              clearable></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="3">
                                <el-form-item label="开票金额:" label-width="90px">
                                    <vxe-input ref="invoice_money" size="mini" @change="getTotalBalanceMoney()"
                                               type="float" style="width: 100px"
                                               :controls="false" v-model="formData.invoice_money"
                                               @keyup.native.enter="$_blurNext('invoice_money'),$_focusNext('remark')">
                                        >
                                    </vxe-input>
                                </el-form-item>
                            </el-col>

                            <el-col :sm="12" :md="13">
                                <el-form-item label="发票备注:">
                                    <el-input ref="remark" v-model="formData.remark" size="mini"
                                              :disabled="formData.is_cancel"
                                              @change="isDataChanage = true"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="21">
                                <el-form-item label="所选单号:" label-width="100px">
                                    <el-input :value="invoice_bill_id" size="mini"
                                              :readonly="true"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="3">
                                <el-button v-show="!is_invoice_bill" style=" margin-top: 8px;margin-bottom: 8px;margin-left: 8px "
                                           :disabled="invoiceSaveList.length == 0"
                                           type="success" @click="buildOrUpdateInvoice()"
                                           size="mini">生成发票
                                </el-button>
                                <el-button v-show="is_invoice_bill" style=" margin-top: 8px;margin-bottom: 8px;margin-left: 8px "
                                           type="success" @click="buildOrUpdateInvoice()"
                                           size="mini">更新发票
                                </el-button>
                                <el-button style=" margin-top: 8px;margin-bottom: 8px;margin-left: 8px "
                                           :disabled="!is_invoice_bill"
                                           type="danger" plain @click="deleteInvoice()"
                                           size="mini">删除发票
                                </el-button>
                            </el-col>

                        </el-row>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                keep-source
                                show-footer
                                ref="xGrid"
                                align="left"
                                row-id="id"
                                :footer-method="footerMethod"
                                highlight-current-row
                                size="mini"
                                :height="(this.getViewHeight() - 220)+''"
                                :print-config="{}"
                                :data="tableDataMain"
                                :columns="tableColumn"
                                :cell-class-name="cellClassName"
                                @cell-click="cellSelectEvent"
                                @cell-dblclick="cellClickEvent"
                                :mouse-config="{selected: true}"
                                :export-config="{mode:'current',type: ['xlsx'] ,types: ['xlsx', 'csv', 'html', 'xml', 'txt'],isFooter:true,isColgroup:true}"
                                :edit-config="{showIcon:false,trigger: 'dblclick', mode: 'cell', showStatus: true}"
                                :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:true}">
                            <!-- 开票备注 -->
                            <template #invoice_remark_edit="{ row}">
                                <vxe-input v-model="row.invoice_remark_kd"
                                           @blur="remarkEvent(row)"
                                           :disabled="row.type_name != '销售开单' && row.type_name != '发票单'"></vxe-input>
                            </template>
                            <template #pager>
                                <vxe-pager
                                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[50,500,1000,2500,5000,50000]"
                                        :current-page.sync="tablePage.currentPage"
                                        :page-size.sync="tablePage.pageSize"
                                        :total="tablePage.total"
                                        @page-change="handlePageChange">
                                </vxe-pager>
                            </template>
                        </vxe-grid>
                    </el-col>
                </el-row>

                <vxe-modal v-model="showModel.isShow" width="600" :title="showModel.bill_id">
                    <template #default>
                        <vxe-grid
                                ref="modalGrid"
                                resizable
                                show-overflow
                                auto-resize
                                height="300"
                                size="mini"
                                highlight-current-row
                                :data="tableDataModel"
                                :columns="tableColumnModel">
                        </vxe-grid>
                    </template>
                </vxe-modal>

            </el-form>
        </el-main>
    </div>
</template>


<script>
    import {filterRender} from "@/utils/gird-filter";

    export default {

        name: "InvoiceClientReceivableDetail",
        data() {
            return {
                is_invoice_bill:false,
                showModel: {
                    isShow: false,//显示model层
                    bill_id: '',//显示model层
                },
                invoiceSaveList: [],//开票的数组
                invoice_bill_id: '',//开票的ID用来绑定数组
                before_balance_company:'',
                end_balance_company:'',
                pickerDate: [],
                formData:{
                    company_uuid :'',
                    company_name :'',
                    bill_date: new Date(),//开单日期
                    total_amount: 0,//所选进金额
                    total_balance_money : 0,//所选多(少)开票金额
                    invoice_money: 0,//开票金额
                    remark: '',//开票备注
               },
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
               },
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '本月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth() + 1;
                            if (month < 10) {
                                month = '0' + month;
                           }
                            // 本月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            picker.$emit('pick', [startDate, new Date(year + '-' + month + '-' + new Date(year, month, 0).getDate() + ' 23:59:00')]);
                       }
                   }, {
                        text: '上个月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth();
                            if (month == 0) {
                                month = 12;
                                year = year - 1;
                           }
                            if (month < 10) {
                                month = '0' + month;
                           }
                            var myDate = new Date(year, month, 0);
                            // 上个月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            // 上个月最后一天
                            var endDate = new Date(year + '-' + month + '-' + myDate.getDate() + ' 23:59:00');
                            picker.$emit('pick', [startDate, endDate]);
                       }
                   }]
               },
                tableDataModel: [],
                tableColumnModel: [
                    {
                        field:'is_cancel', title: '状态', width: 30, formatter: ({cellValue}) => {
                            if (cellValue) {
                                return '已作废'
                            } else {
                                return '正常'
                            }
                        }
                    },
                    {field:'only_bill_id', title: '单号', width: 140},
                    {field:'total_amount', width: 100, title: '金额'},
                    {field:'registrar', width: 50, visible: true, title: '创建人'},
                    {field:'only_bill_date', width: 80, visible: true, title: '创建日期', formatter: ({cellValue}) => {
                            return this.$_formatDate(cellValue, 'yyyy-MM-dd')
                        }
                    },
                    {field:'last_name', width: 90, visible: true, title: '最后修改人'},
                    {field:'last_date', width: 150, visible: true, title: '最后修改日期'},
                ],
                tableColumn: [
                    {
                        field:'type_name', width: 55, title: '类型', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceClientReceivableDetail/searchHeard', this.pickerDate,this.formData)
                       }
                   },{
                        field:'bill_date', width: 75, title: '日期', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceClientReceivableDetail/searchHeard', this.pickerDate,this.formData)
                       },formatter: ({cellValue}) => {return this.formatDate(cellValue,'yyyy-MM-dd')}
                   }, {
                        field:'bill_id', width: 125, title: '单号', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceClientReceivableDetail/searchHeard', this.pickerDate,this.formData)
                       }
                   },
                    {
                        field:'subject_name_cash_data', width: 170, title: '科目名称', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceClientReceivableDetail/searchHeard', this.pickerDate,this.formData)
                       }
                   },
                    {
                        field:'tax_tate_warehouse_bill', width: 40, title: '税', formatter: ({cellValue}) => {
                            if (this.isBlankVue(cellValue)) {
                                return ''
                            } else {
                                return cellValue + '%'
                            }
                        },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceClientReceivableDetail/searchHeard', this.pickerDate,this.formData)
                        }
                    },{
                        field:'remark', width: 40, title: '备注', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceClientReceivableDetail/searchHeard', this.pickerDate,this.formData)
                        }
                    },
                    {
                        field:'in_money', width: 120, title: '进金额(本期销售单)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceClientReceivableDetail/searchHeard', this.pickerDate,this.formData)
                       }
                   },{
                        field:'out_money', width: 120, title: '出金额(本期收款)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceClientReceivableDetail/searchHeard', this.pickerDate,this.formData)
                       }
                   },{
                        field:'before_money',visible:false, width: 120, title: '小结', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceClientReceivableDetail/searchHeard', this.pickerDate,this.formData)
                       }
                   },{
                        field:'b_money', width: 120, title: '结存', formatter: ({cellValue,row}) => {
                            if (row.type_name == '发票单'){
                                return '';
                            }else{
                                return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                            }
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceClientReceivableDetail/searchHeard', this.pickerDate,this.formData)
                       }
                   },
                    {
                        field:'invoice_money', width: 90, title: '开票金额', formatter: ({cellValue,row}) => {
                            if (row.type_name != '发票单') {
                                return '';
                            } else {
                                return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                            }
                        }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceClientReceivableDetail/searchHeard', this.pickerDate,this.formData)
                        }
                    },
                    {
                        field:'sales_man', width: 45, title: '业务', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceClientReceivableDetail/searchHeard', this.pickerDate,this.formData)
                       }
                   },{
                        field:'tax_tate_warehouse_bill2', width: 25, title: '开票', formatter: ({cellValue}) => {
                            if (cellValue == '否') {
                                return ''
                            }else{
                                return cellValue
                            }
                        },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoice/searchHeard2', this.pickerDate, this.formData)
                        }
                    },
                    {
                        field:'has_invoice', width: 25, title: '已开', formatter: ({cellValue}) => {
                            if (cellValue == '已开') {
                                return '✔';
                            }
                        },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoice/searchHeard2', this.pickerDate, this.formData)
                        }
                    },{
                        field:'invoice_remark_kd',
                        width: 200,
                        title: '单据备注',
                        editRender: {autofocus: 'input.vxe-input--inner'},
                        slots: {
                            edit: 'invoice_remark_edit',
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoice/searchHeard2', this.pickerDate, this.formData)
                        }
                    },
                    {
                        field:'invoice_remark',
                        title: '发票单备注',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoice/searchHeard2', this.pickerDate, this.formData)
                        }
                    },
                ],
                tableDataMain: [],
                filterData: {},
                sortData: {},
           }
       },
        methods: {
            cellClassName({row,column}) {
                if (this.$refs.xGrid.isCheckedByCheckboxRow(row)) {
                    return 'green-row';
                } else if (column.property == 'bill_id' && (row.type_name == '销售开单' || row.type_name == '发票单')) {
                    return 'invoice-col-blue';
                } else if ((row.type_name == '销售开单' ) && (column.property == 'has_invoice' || column.property == 'invoice_remark_kd')) {
                    return 'col-fail';
                }else if (row.type_name == '收款单' || row.type_name == '订金单') {
                    return 'unEditor';
                } else if (row.type_name == '发票单') {
                    return 'col-yellow';
                }
            },
            remarkEvent(row) {
                //console.log(row)
                this.$axios({
                    method: 'post',
                    url: '/admin/invoice/changeInvoiceRemarkKD',
                    data: {
                        only_bill_id: row.bill_id,
                        invoice_remark_kd: row.invoice_remark_kd,
                        type:row.type
                    }
                }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.$message({
                            showClose: true,
                            message: '修改成功',
                            type: 'success'
                        });
                    } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                        });
                        return
                    }
                }).catch((error) => {
                    console.log(error)
                });
            },
            //选中一行
            cellSelectEvent({row, rowIndex}) {
                if (this.$refs.xGrid.isCheckedByCheckboxRow(row)) {
                    this.$refs.xGrid.setCheckboxRow([this.tableDataMain[rowIndex]], false)
                    this.$refs.xGrid.setCurrentRow(null);
                } else {
                    this.$refs.xGrid.setCheckboxRow([this.tableDataMain[rowIndex]], true)
                }

                //this.$refs.xGrid.setCurrentRow(row);
                //console.log(this.$refs.xGrid.getCurrentRecord())
                this.sumCheckBoxRows();
                this.getTotalBalanceMoney();
                this.getSelectRecords();
                if (this.$refs.xGrid.getCurrentRecord() != null && this.$refs.xGrid.getCurrentRecord() != 'undefined' && this.$refs.xGrid.getCurrentRecord().type_name == '发票单'){
                    //console.log("进入发票单")
                    let row = this.$refs.xGrid.getCurrentRecord();
                    this.is_invoice_bill = true;
                    this.invoice_bill_id = row.bill_id;
                    this.formData.remark = row.invoice_remark;
                    this.formData.total_amount = row.in_money;
                    this.formData.invoice_money = row.invoice_money;
                    this.formData.total_balance_money = row.total_balance_money;
                }else{
                    //console.log("没有进入发票单")
                    this.invoice_bill_id = this.invoiceSaveList.toString();
                    this.is_invoice_bill = false;
                    this.formData.remark = '';
                    this.formData.invoice_money = '';
                }
            },
            //选中合计
            sumCheckBoxRows() {
                this.formData.total_amount = 0;
                let checkBoxRows = this.$refs.xGrid.getCheckboxRecords(true);
                //console.log(this.$refs.xGrid.getCurrentRecord())
                //console.log(this.is_invoice_bill)
                for (let i = 0; i < checkBoxRows.length; i++) {
                    let row = checkBoxRows[i];
                    if (this.isNumberVue(row.in_money)) {
                        this.formData.total_amount = this.$XEUtils.add(this.formData.total_amount, row.in_money);
                    }
                }
            },
            searchModalId(){
                this.$axios({
                    method: 'post',
                    url: '/admin/invoice/searchCashTypeId',
                    data: {
                        bill_id:this.showModel.bill_id,
                    }
                }).then((response) => {//这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log('response', response)
                    //console.log('response.data.tableData',response.data.tableData)
                    if (response.status == 200 && response.data.state == "ok") {
                        if (this.isBlankVue(response.data.list)) {
                            this.tableDataModel = [];
                        } else {
                            this.tableDataModel = response.data.list
                        }
                    }
                }).catch((error) => {
                    console.log(error)
                });
            },
            //双击勾单
            cellClickEvent({row, column}) {
                //console.log(column.property);
                //console.log(row.type_name);
               if ((row.type_name == '发票单' && column.property == 'bill_id') || (row.type_name == '销售开单' && column.property == 'bill_id')) {
                    this.showModel.isShow = true;
                    this.showModel.bill_id = row.bill_id;
                    this.sortData = {}
                    //查询单价
                    this.searchModalId();
                }else if (row.type_name == '销售开单' && column.property == 'has_invoice') {
                    let b = true;
                    if (row.has_invoice == '已开'){
                        //已开，则取反值
                        b = false;
                    }
                    this.$axios({
                        method: 'post',
                        url: '/admin/invoice/hasInvoice',
                        data: {
                            only_bill_id: row.bill_id,
                            has_invoice: b
                        }
                    }).then((response) => {          //这里使用了ES6的语法
                        //console.log(response)       //请求成功返回的数据
                        if (response.status === 200 && response.data.state == 'ok') {
                            this.$message({
                                showClose: true,
                                message: '设置成功！',
                                type: 'success'
                            });
                            this.search();
                        } else {
                            this.$message({
                                showClose: true,
                                message: response.data.msg,
                                type: 'error'
                            });
                            this.search();
                            return
                        }
                    }).catch((error) => {
                        console.log(error)
                    });
                }
            },
            //计算冲账金额
            getTotalBalanceMoney() {
                this.formData.total_balance_money = this.$XEUtils.subtract(this.formData.total_amount, this.formData.invoice_money)
            },
            //删除发票
            deleteInvoice(){
                this.$XModal.confirm('您确定要删除：' + this.invoice_bill_id + '开单发票?').then(type => {
                    if (type === 'confirm') {
                        this.getSelectRecords();
                        this.$axios({
                            method: 'post',
                            url: '/admin/invoice/deleteInvoice',
                            data: {
                                invoice_bill_id: this.invoice_bill_id,
                            }
                        }).then((response) => {//这里使用了ES6的语法
                            if (response.status == 200 && response.data.state == "ok") {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'success'
                                });
                                this.search();
                            } else {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                                });
                                return
                            }
                        }).catch((error) => {
                            console.log(error)
                        });
                    }
                });
            },
            //生成发票
            async buildOrUpdateInvoice() {
                let message = '您确定要生成：' + this.invoice_bill_id + '销售开单发票?';
                if (this.is_invoice_bill){
                    message = '您确定要更新：' + this.invoice_bill_id + '发票单?';
                }
                this.$XModal.confirm(message).then(type => {
                    //const $table = this.$refs.xTable
                    if (type === 'confirm') {
                        const loading = this.$loading({
                            lock: true,
                            text: '饮茶先啦，数据查询中，请耐心等待!',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                        this.getSelectRecords();
                        this.$axios({
                            method: 'post',
                            url: '/admin/invoice/buildOrUpdateInvoice',
                            data: {
                                is_invoice_bill: this.is_invoice_bill,//是发票类型
                                invoice_bill_id: this.invoice_bill_id,
                                formData: this.formData,
                            }
                        }).then((response) => {//这里使用了ES6的语法
                            //(response)       //请求成功返回的数据
                            //console.log('response', response)
                            //console.log('response.data.tableData',response.data.tableData)
                            loading.close();
                            if (response.status == 200 && response.data.state == "ok") {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'success'
                                });
                                this.search();
                            } else {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                                });
                                return
                            }
                        }).catch((error) => {
                            loading.close();
                            console.log(error)
                        });
                    }
                });
            },
            //付款单位
            companyNameChangeEvent(item, nameType) {
                //console.log(item,nameType)
                if (nameType == 'customer_name') {
                    this.formData.company_name = item.customer_name;
               } else if (nameType == 'supplier_name') {
                    this.formData.company_name = item.supplier_name;
               }
                this.formData.company_uuid = item.company_uuid;
           },
            searchNewUrl(){
                //如果日期修改过、公司修改过，则用跳转方式，否则使用搜索
                //const{href} =
                this.$router.push(
                        {
                            path: "/invoiceClientReceivableDetail",
                            query: {
                                company_uuid : this.formData.company_uuid,
                                company_name : this.formData.company_name,
                                startDate : this.formatDate(this.pickerDate[0],'yyyy-MM-dd'),
                                endDate : this.formatDate(this.pickerDate[1],'yyyy-MM-dd'),
                            }
                        }
                    )
                //window.open(href,'_self');
            },
            search() {
                this.sortData = {
                    bill_date:'asc',
                    type:'asc',
                    bill_id:'asc',
                    subject_name_cash_data:'asc',
                    cash_data_id:'asc',
                }
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据查询中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                let searchUrl = '/admin/invoiceClientReceivableDetail/search'
                this.$axios({
                    method: 'POST',
                    url: searchUrl,
                    data: {
                        currentPage: this.tablePage.currentPage,
                        pageSize: this.tablePage.pageSize,
                        sortData: this.sortData,
                        filterData: this.filterData,
                        pickerDate: this.pickerDate,
                        formData: this.formData
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    loading.close();
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.before_balance_company = response.data.before_balance_company
                        this.end_balance_company = response.data.end_balance_company
                        this.tableDataMain = response.data.page.list
                        this.tablePage.pageSize = response.data.page.pageSize
                        this.tablePage.total = response.data.page.totalRow
                        this.tablePage.currentPage = response.data.page.pageNumber
                   }
               }).catch((error) => {
                    loading.close();
                    console.log(error)
               });
           },
            handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.search();
           },

            sumNum(list, field) {
                let count = Number(0)
                list.forEach(item => {
                    count = this.$XEUtils.add(count, item[field]);
               })
                return count;
           },
            getSelectRecords() {
                this.invoiceSaveList = [];
                if (this.$refs.xGrid.getCheckboxRecords().length > 0) {
                    for (let i = 0; i < this.$refs.xGrid.getCheckboxRecords().length; i++) {
                        if ("销售开单" == this.$refs.xGrid.getCheckboxRecords()[i].type_name) {
                            this.invoiceSaveList.push(this.$refs.xGrid.getCheckboxRecords()[i].bill_id);
                        }
                    }
                }
            },
            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                   } else {
                        if (
                            column.property === 'before_count' || column.property === 'before_stock_amount' || column.property === 'before_weighing_heavy_total'
                            || column.property === 'now_single_count' || column.property === 'now_naked_price' || column.property === 'now_weighing_heavy'
                            || column.property === 'now_single_count_kd' || column.property === 'now_stock_amount_kd_total' || column.property === 'now_weighing_heavy_kd'
                            || column.property === 'total_count' || column.property === 'total_stock_amount' || column.property === 'total_weighing_heavy_total'
                            || column.property === 'in_money' || column.property === 'out_money'|| column.property === 'invoice_money'
                        ) {
                            sums.push(this.sumNum(data, column.property))
                        }else {
                            sums.push('')
                        }
                   }
               })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
           },

       },
        created() {
            this.$nextTick(() => {

                this.$_searchCustomerAndSupplier();
                this.loading = true
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
                if (!this.isBlankVue(this.$route.query.company_uuid)){
                    this.formData.company_uuid = this.$route.query.company_uuid,
                    this.formData.company_name = this.$route.query.company_name,
                    //console.log('this.$route.query.startDate',this.$route.query.startDate)
                    //console.log('this.$route.query.endDate',this.$route.query.endDate)
                    //console.log('start_date',new Date(Date.parse(this.$route.query.startDate.replace(/-/g,  "/"))))
                    //console.log('end_date',new Date(Date.parse(this.$route.query.endDate.replace(/-/g,  "/"))))
                    //console.log('this.XEUtils.toStringDate(this.$route.query.startDate, \'yyyy-MM-dd\')',this.XEUtils.toStringDate(this.$route.query.startDate, 'yyyy-MM-dd'))
                    this.pickerDate.push(new Date(Date.parse(this.$route.query.startDate.replace(/-/g,  "/"))))
                    this.pickerDate.push(new Date(Date.parse(this.$route.query.endDate.replace(/-/g,  "/"))))
                    this.search();
               }
           })
       }
   }
</script>

<style scoped>
    /deep/ .vxe-body--row {
        font-size: 13px !important;
    }
</style>
